import React from 'react';

import Layout from '../components/layout';

const NotFoundPage = () => (
    <Layout>
        <div style={{ width: '100%', textAlign: 'center' }}>
            <h1 style={{ color: 'white' }}>404: Not Found</h1>
            <p style={{ color: 'white' }}>This page does not exist. Go back!</p>
        </div>
    </Layout>
);

export default NotFoundPage;
